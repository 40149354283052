import React from 'react'
import styled, { css } from 'styled-components'
import Img from 'gatsby-image'
const { formatToTimeZone } = require('date-fns-timezone/dist/formatToTimeZone')
import { flag } from 'country-emoji'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Share from 'components/Share'
import Save from 'components/Save'
import TransitionLink from 'components/TransitionLink'
import { sizes } from 'styles'

const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  @media ${sizes.tabletPortrait} {
    margin-bottom: 0;
  }
`

const StyledImage = styled(Img)`
  width: 100%;
  border-radius: 5px;
  object-fit: cover;

  > picture > img {
    transform: scale3d(1, 1, 1);
    transition: transform 400ms !important;

    :hover {
      transform: scale3d(1.05, 1.05, 1);
    }
  }
`

const StyledLink = styled(TransitionLink)`
  text-decoration: none;
  color: inherit;

  :hover {
    opacity: 1;
  }
`

const Title = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 30px;
  color: ${({ theme }) => theme.color.textSecondary};

  @media ${sizes.desktop} {
    margin-bottom: 15px;
  }
`

const Speakers = styled.div`
  margin-bottom: 10px;

  @media ${sizes.desktop} {
    margin-bottom: 0;
  }
`

const StyledEventListItem = styled.div`
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;

  > :first-child {
    width: 87%;
  }

  @media ${sizes.tabletPortrait} {
    flex-direction: row;

    > :first-child {
      flex: 4;
      width: 100%;
      margin-right: 25px;
    }

    > :nth-child(2) {
      flex: 5;
    }
  }

  @media ${sizes.tabletLandscape} {
    flex-direction: column;

    > :first-child {
      width: 80%;
      margin-bottom: 30px;
    }
  }

  @media ${sizes.desktop} {
    flex-direction: row;
    > :first-child {
      flex: initial;
      width: 335px;
      margin-bottom: 0;
    }
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 24px;

  > p {
    margin: 10px 0;
  }
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.color.tertiary};
  opacity: 0.3;

  ${({ showDivider }) =>
    !showDivider &&
    css`
      display: none;
    `}
`

const iconStyles = css`
  margin-right: 10px;
  margin-left: 2px;
  color: ${({ theme }) => theme.color.primary};
`

const EventDate = styled.div`
  margin-bottom: 9px;
  font-weight: 600;

  > svg {
    ${iconStyles};
  }
`

const EventLocation = styled.div`
  margin-bottom: 15px;
  > span:first-of-type {
    font-weight: 600;
  }
`

const Flag = styled.span`
  margin-right: 8px;
  font-size: 18px;
  vertical-align: middle;
`

const StyledSave = styled(Save)`
  margin-left: 12px;
`

const Online = styled.div`
  > svg {
    ${iconStyles};
    margin-left: 1px;
  }
`

const EventListItem = ({ event, showDivider, className }) => {
  const {
    title,
    slug,
    speakers,
    eventStartTime,
    eventEndTime,
    eventTimeZoneName,
    calendarEventDescription,
    online,
    city,
    country,
    featuredImage,
  } = event

  return (
    <>
      <Divider className={className} showDivider={showDivider} />
      <StyledEventListItem>
        <StyledLink to={`/event/${slug}`}>
          {featuredImage && (
            <ImageContainer>
              <StyledImage
                fluid={{
                  ...featuredImage.fluid,
                  aspectRatio: 4 / 3,
                }}
              />
            </ImageContainer>
          )}
        </StyledLink>
        <Info>
          <div>
            <StyledLink to={`/event/${slug}`}>
              <Title>{title}</Title>
            </StyledLink>
            {speakers && (
              <Speakers>with {speakers.map((x) => x.name).join(', ')}</Speakers>
            )}
          </div>
          <div>
            <EventDate>
              <FontAwesomeIcon icon={['fal', 'calendar-star']} />
              {formatToTimeZone(new Date(eventStartTime), 'MMMM D, YYYY', {
                timeZone: eventTimeZoneName,
              })}
            </EventDate>
            {online && (
              <Online>
                <FontAwesomeIcon icon={['fal', 'globe']} />
                <b>Online event</b>
              </Online>
            )}
            <EventLocation>
              {country && <Flag>{flag(country)}</Flag>}
              <b>{city}</b>
              {country && <span>, {country}</span>}
            </EventLocation>
            <Share
              shareTitle={title}
              shareLink={
                typeof window !== 'undefined'
                  ? `${window.location.protocol}//${window.location.host}/blog/${slug}`
                  : ''
              }
            />
            <StyledSave
              saveTitle={title}
              event={{
                title,
                description:
                  calendarEventDescription?.calendarEventDescription || '',
                start: eventStartTime,
                end: eventEndTime,
              }}
            />
          </div>
        </Info>
      </StyledEventListItem>
    </>
  )
}

export default EventListItem
