import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import copy from 'copy-to-clipboard'
import { FiShare } from 'react-icons/fi'
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaCopy } from 'react-icons/fa'
import { AiFillCloseCircle } from 'react-icons/ai'
import Button from 'components/Button'
import Portal from 'components/Portal'
import { zIndexes } from 'styles'

const ShareText = styled.span`
  display: flex;
  align-items: center;
  opacity: 0.5;
  transition: opacity 200ms;

  :hover {
    opacity: 1;
  }

  > :last-child {
    margin-left: 5px;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: ${zIndexes.modal};
  opacity: ${({ $hidden }) => ($hidden ? 0 : 1)};
  pointer-events: ${({ $hidden }) => ($hidden ? 'none' : 'all')};
  transition: opacity 200ms;
`

const Popup = styled(animated.div)`
  position: relative;
  width: 100%;
  max-width: 380px;
  margin: 20px;
  border-radius: 5px;
  background-color: ${({ theme }) => theme.color.white};
  box-shadow: ${({ theme }) => theme.shadow};
`

const PopupHeading = styled.div`
  padding: 16px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: ${({ theme }) => theme.color.tertiary};
  background-color: #e3e3ff;
`

const PopupContent = styled.div`
  padding: 16px;
`

const CloseIcon = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  top: -8px;
  right: -8px;
  cursor: pointer;

  ::before {
    position: absolute;
    left: 2px;
    top: 3px;
    content: ' ';
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.color.white};
    z-index: 0;
  }

  > svg {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #ce6e6e;
    font-weight: bold;
  }
`

const ShareLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  display: flex;
  align-items: center;
  margin: 10px 0;
  font-weight: bold;
  color: ${({ theme }) => theme.color.secondary};
  text-decoration: none;

  :first-of-type {
    margin-top: 0;
  }

  :last-of-type {
    margin-bottom: 0;
  }

  > svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }
`

const Share = ({ shareTitle, shareLink, className }) => {
  const [showPopup, setShowPopup] = useState(false)
  const [copiedLink, setCopiedLink] = useState(false)
  const [props, animate] = useSpring(() => ({
    transform: 'translate3d(0px, 0px, 0px)',
    from: { transform: 'translate3d(0px, -20px, 0px)' },
  }))

  useEffect(() => {
    if (showPopup) {
      animate.start({ transform: 'translate3d(0px, -20px, 0px)' })
      return
    }
    animate.start({ transform: 'translate3d(0px, 0px, 0px)' })
  }, [showPopup])

  return (
    <>
      <Button
        variant="share"
        onClick={() => setShowPopup(true)}
        className={className}
      >
        <ShareText>
          Share
          <FiShare />
        </ShareText>
      </Button>
      <Portal name="modal-portal">
        <Overlay onClick={() => setShowPopup(false)} $hidden={!showPopup}>
          <Popup onClick={(e) => e.stopPropagation()} style={props}>
            <CloseIcon onClick={() => setShowPopup(false)}>
              <AiFillCloseCircle />
            </CloseIcon>
            <PopupHeading>{`Share ${shareTitle}`}</PopupHeading>
            <PopupContent>
              <ShareLink
                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(
                  shareLink
                )}`}
              >
                <FaTwitter />
                Share on Twitter
              </ShareLink>
              <ShareLink
                href={`https://www.facebook.com/dialog/share?app_id=376183856180246&display=popup&href=${encodeURIComponent(
                  shareLink
                )}`}
              >
                <FaFacebookF />
                Share on Facebook
              </ShareLink>
              <ShareLink
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
                  shareLink
                )}`}
              >
                <FaLinkedinIn />
                Share on LinkedIn
              </ShareLink>
              <ShareLink
                onClick={(e) => {
                  e.preventDefault()
                  setCopiedLink(true)
                  copy(shareLink)
                  setTimeout(() => {
                    setCopiedLink(false)
                  }, 1000)
                }}
              >
                <FaCopy />
                {copiedLink ? 'Copied!' : 'Copy link'}
              </ShareLink>
            </PopupContent>
          </Popup>
        </Overlay>
      </Portal>
    </>
  )
}

export default Share
