import React from 'react'
import styled, { css } from 'styled-components'
import { useTrail, animated, config } from 'react-spring'
import { graphql } from 'gatsby'
import isFuture from 'date-fns/isFuture'
import { partition } from 'ramda'
import Container from 'components/Container'
import Seo from 'components/Seo'
import PostLayout from 'components/PostLayout'
import PageTitle from 'components/PageTitle'
import SubscribeBox from 'components/SubscribeBox'
import EventListItem from 'components/EventListItem'
import { sizes } from 'styles'

const StyledSubscribeBox = styled(SubscribeBox)`
  width: 100%;
  max-width: 370px;
`

const StyledPostLayout = styled(PostLayout)`
  @media ${sizes.desktop} {
    > aside {
      padding-top: 100px;
    }

    > article {
      margin-right: 40px;
    }
  }
`

const Info = styled.div`
  max-width: 85%;
`

const StyledEventListItem = styled(EventListItem)`
  margin-top: 40px;
`

const Divider = styled.div`
  margin-top: 32px;
  display: flex;
  align-items: center;
  font-size: 22px;
  font-family: ${({ theme }) => theme.font.headings};
  font-style: italic;
  color: ${({ theme }) => theme.color.tertiary};
`

const Line = styled.div`
  flex: 1;
  height: 1px;
  margin-left: 12px;
  background-color: ${({ theme }) => theme.color.tertiary};
  opacity: 0.3;
`

const StyledEventList = styled.div`
  ${({ faded }) =>
    faded &&
    css`
      opacity: 0.5;
    `};
`

const EventList = ({ eventList, title, faded }) => {
  const getTrail = (eventList) =>
    useTrail(eventList.length, {
      config: config.stiff,
      delay: 300,
      opacity: 1,
      transform: 'translate3d(0px, 0px, 0px)',
      from: { opacity: 0, transform: 'translate3d(0px, 20px, 0px)' },
    })

  return (
    <StyledEventList faded={faded}>
      <Divider>
        <span>{title}</span>
        <Line />
      </Divider>
      {getTrail(eventList).map((props, i) => {
        const event = eventList[i].node
        return (
          <animated.div key={i} style={props}>
            <StyledEventListItem
              event={event}
              showDivider={i === 0 ? false : true}
            />
          </animated.div>
        )
      })}
    </StyledEventList>
  )
}

const MainContent = ({ title, description, events }) => {
  const [upcoming, past] = partition(
    (event) => isFuture(new Date(event.node.eventStartTime)),
    events
  )
  const sortedUpcoming = upcoming.sort(
    (a, b) => new Date(a.node.eventStartTime) - new Date(b.node.eventStartTime)
  )

  return (
    <>
      <Info>
        <PageTitle>{title}</PageTitle>
        <p>{description.description}</p>
      </Info>

      {upcoming?.length > 0 && (
        <EventList eventList={sortedUpcoming} title="Upcoming" />
      )}
      {past?.length > 0 && <EventList eventList={past} title="Past" faded />}
    </>
  )
}

const Events = ({ data }) => {
  const { allContentfulEvent, allContentfulEventsPage } = data
  const { title, description } = allContentfulEventsPage?.edges[0]?.node
  return (
    <>
      <Seo
        title="Events"
        image={{ src: undefined, width: 1600, height: 900 }}
        description="Building Remotely events are created to explore the latest topics and trends in the world of remote."
      />
      <Container>
        <StyledPostLayout
          mainContent={
            <MainContent
              title={title}
              description={description}
              events={allContentfulEvent?.edges}
            />
          }
          asideContent={<StyledSubscribeBox />}
        />
      </Container>
    </>
  )
}

export const pageQuery = graphql`
  query eventsPageQuery {
    allContentfulEventsPage {
      edges {
        node {
          title
          description {
            description
          }
        }
      }
    }
    allContentfulEvent(sort: { fields: [eventStartTime], order: DESC }) {
      edges {
        node {
          id
          title
          slug
          featuredImage {
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid
            }
          }
          eventStartTime
          eventEndTime
          eventTimeZoneName
          calendarEventDescription {
            calendarEventDescription
          }
          description {
            json
          }
          speakers {
            name
          }
          online
          city
          country
        }
      }
    }
  }
`

export default Events
