import React from 'react'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'
import { sizes } from 'styles'

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media ${sizes.tabletLandscape} {
    grid-template-columns: 1fr 370px;
  }
`

const MainContent = styled.article`
  > p {
    line-height: 26px;
  }

  @media ${sizes.desktop} {
    max-width: 700px;
  }
`

const AsideContent = styled(animated.aside)`
  height: min-content;

  @media ${sizes.tabletLandscape} {
    position: sticky;
    top: 110px;
    padding-left: 40px;
  }

  @media ${sizes.desktop} {
    margin-top: 0;
    padding-left: 0;
  }

  > * {
    margin-top: 40px;
  }
`

const PostLayout = ({ mainContent, asideContent, className }) => {
  const asideSpring = useSpring({
    transform: 'translateY(0)',
    from: { transform: 'translateY(20px)' },
  })
  return (
    <Grid className={className}>
      <MainContent>{mainContent}</MainContent>
      <AsideContent style={asideSpring}>
        {asideContent && asideContent}
      </AsideContent>
    </Grid>
  )
}

export default PostLayout
