import { useState, useLayoutEffect } from 'react'
import { createPortal } from 'react-dom'
// Utility component for react portals, see https://reactjs.org/docs/portals.html

const Portal = ({ name, children }) => {
  const [containerEl, setContainerEl] = useState(null)

  useLayoutEffect(() => {
    setContainerEl(document.getElementById(name))
  }, [])

  if (!containerEl) return null
  return createPortal(children, containerEl)
}

export default Portal
